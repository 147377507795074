'use client'
import { Stack, Group, Kbd, Text, useMantineTheme } from '@mantine/core'
import { useOs } from '@mantine/hooks'
import { useAppContext } from '../wrapper/appContextProvider'
import { PUBLIC_ROUTES_TOP } from '~/lib/route-groups'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

type DCFooterProps = {
  version: string
}

export default function DCFooter({ version }: DCFooterProps) {
  const { context } = useAppContext()
  const os = useOs()
  const pathname = usePathname()

  const topLevelPath = useMemo(() => {
    const segments = pathname.split('/')
    return segments.length > 1 ? segments[1] : ''
  }, [pathname])

  const footerText = `\u00A9 Flusso Pty Ltd ${new Date(Date.now()).getUTCFullYear()}`

  const activeApp = () => {
    if (pathname.includes('participate')) return 'Data Coalitions - Participate'
    return 'Data Coalitions'
  }

  return (
    <Stack pos="fixed" h="3rem" w="100%" pt={6} px={8} style={(theme) => ({ backgroundColor: theme.colors.gray[0] })}>
      <Group justify="space-between">
        <Text>
          {context.user
            ? `Logged in as: ${context.user.displayName}`
            : !PUBLIC_ROUTES_TOP.includes(topLevelPath)
            ? 'User not logged in'
            : ''}
        </Text>
        <Group gap={5}>
          {os === 'macos' ? <Kbd>⌘</Kbd> : <Kbd>ctrl</Kbd>}
          <Text>+</Text>
          <Kbd>K</Kbd>
          <Text>for quick navigation</Text>
        </Group>
        <Group>
          <Text>{`${activeApp()} ${version}`}</Text>
          <Text style={{ textAlign: 'end' }}>{footerText}</Text>
        </Group>
      </Group>
    </Stack>
  )
}
