import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/app/authWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/components/wrapper/apolloWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/components/wrapper/appContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/components/wrapper/mantineWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/components/wrapper/reactQueryWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/components/wrapper/session.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/components/wrapper/shell.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/apps/dc/components/wrapper/UserInitWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/node_modules/.pnpm/@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@19.0.1_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/runnerspace/actions-runner/_work/flussomono/flussomono/node_modules/.pnpm/@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@19.0.1_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/runnerspace/actions-runner/_work/flussomono/flussomono/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./AvenirNextRegular.woff2\",\"display\":\"swap\",\"weight\":\"500\",\"style\":\"normal\",\"variable\":\"--font-avenir\"}],\"variableName\":\"avenir\"}");
;
import(/* webpackMode: "eager" */ "/runnerspace/actions-runner/_work/flussomono/flussomono/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./MonumentExtended-Regular.woff2\",\"display\":\"swap\",\"weight\":\"500\",\"style\":\"normal\",\"variable\":\"--font-monument-regular\"}],\"variableName\":\"monunumentRegular\"}");
;
import(/* webpackMode: "eager" */ "/runnerspace/actions-runner/_work/flussomono/flussomono/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./MonumentExtended-Light.woff2\",\"display\":\"swap\",\"weight\":\"500\",\"style\":\"normal\",\"variable\":\"--font-monument-light\"}],\"variableName\":\"monumentLight\"}");
;
import(/* webpackMode: "eager" */ "/runnerspace/actions-runner/_work/flussomono/flussomono/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./MonumentExtended-Black.woff2\",\"display\":\"swap\",\"weight\":\"500\",\"style\":\"normal\",\"variable\":\"--font-monument-black\"}],\"variableName\":\"monunmentBlack\"}");
;
import(/* webpackMode: "eager" */ "/runnerspace/actions-runner/_work/flussomono/flussomono/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./MonumentExtended-Black.woff2\",\"display\":\"swap\",\"weight\":\"500\",\"style\":\"normal\",\"variable\":\"--font-monument-ultrabold\"}],\"variableName\":\"monumentUltraBold\"}");
