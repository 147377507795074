'use client'

import { Avatar, Box, Center, Drawer, Group, Image, LoadingOverlay, Menu } from '@mantine/core'
import { signOut, useSession } from 'next-auth/react'

import Link from 'next/link'
import { useAppContext } from '../components/wrapper/appContextProvider'
import { Menu as MenuIcon } from '@styled-icons/boxicons-regular'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import DCMenu from './DCMenu'

import styles from './styles.module.css'
import { GridDots } from '@styled-icons/fluentui-system-filled'
import DataCoalitionsLogo from '../components/svg/DataCoalitionsLogo'
import DataCoalitionsLogoNoText from '../components/svg/DataCoalitionsLogo-notext'
import AppTray from './AppTray'

const logo = 'https://flussodevstorage.blob.core.windows.net/flussodc/public/logotype.small.png'

const TopLink: React.FC<{
  text: string
  to: string
  active?: boolean
  disabled?: boolean
}> = ({ text, to, active, disabled }) => {
  return (
    <Center mx={12} px="2rem" py={12} className={`${styles['top-bar']} ${active ? 'active' : ''}`}>
      <Link href={to} style={{ textDecoration: 'none' }}>
        {text}
      </Link>
    </Center>
  )
}

export type TopNavItem = 'dashboard' | 'coalitions' | 'feed' | 'docs' | 'marketplace'

export interface TopBarProps {}

export default function TopBar({}: TopBarProps) {
  const { context } = useAppContext()
  const [appMenuOpen, appMenuHandlers] = useDisclosure(false)
  const [appsTrayOpen, appsTrayHandlers] = useDisclosure(false)

  const { data: session } = useSession()

  return (
    <>
      <Group
        justify="space-between"
        px="1rem"
        style={{
          backgroundColor: 'var(--mantine-color-brand-6)'
        }}
      >
        <LoadingOverlay visible={session?.user === null} />
        <Group>
          <Box h="4.9rem" w="10rem">
            <Link href="/" passHref legacyBehavior>
              <Image src={logo} alt="Flusso logo" style={{ cursor: 'pointer' }} />
            </Link>
          </Box>
        </Group>
        {session?.user !== null && session?.user ? (
          <Group className={styles['top-bar-container']}>
            {/* <TopLink
              text="Dashboard"
              to="/dashboard"
              active={context.activeTopItem === 'dashboard'}
              disabled
            /> */}
            <TopLink text="Coalitions" to="/" active={context.activeTopItem === 'coalitions'} />
            <TopLink text="Marketplace" to="/marketplace" active={context.activeTopItem === 'marketplace'} />
            {/* <TopLink text="Feed" to="/" active={context.activeTopItem === 'feed'} /> */}
            <TopLink text="Docs" to="/docs" active={context.activeTopItem === 'docs'} />
          </Group>
        ) : null}

        {session?.user !== null && session?.user !== undefined ? (
          <Group align="center">
            <Box role="button" style={{ cursor: 'pointer' }}>
              <GridDots color="white" size={32} onClick={appsTrayHandlers.toggle} />
            </Box>

            <Group className={styles.userOptionsContainer} gap={4}>
              <Box role="button" style={{ cursor: 'pointer', paddingTop: '8px' }}>
                <DataCoalitionsLogoNoText width={64} onClick={appMenuHandlers.open} />
              </Box>
              <Menu shadow="md">
                <Menu.Target>
                  <Avatar
                    style={{ cursor: 'pointer' }}
                    src={session.user?.image}
                    size="md"
                    radius="xl"
                    alt={session.user?.name ?? 'unknown user'}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>{session.user?.name}</Menu.Label>
                  <Menu.Item onClick={() => signOut()}>Sign Out</Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>
        ) : null}

        <AppTray onCloseAction={appsTrayHandlers.close} isOpen={appsTrayOpen} />

        <Drawer
          position="right"
          size="lg"
          opened={appMenuOpen}
          onClose={appMenuHandlers.close}
          title="Data Coalitions"
          transitionProps={{
            transition: 'slide-left',
            duration: 200,
            timingFunction: 'linear'
          }}
        >
          <DCMenu close={appMenuHandlers.close} />
        </Drawer>
      </Group>
    </>
  )
}
