'use client'

import { gql, useQuery } from '@apollo/react-hooks'
import { AppShell, Flex, Stack } from '@mantine/core'
import React from 'react'
import { User } from '~/lib/generated/graphql'
import { Breadcrumb } from '../layout/Breadcrumb'
import { useAppContext } from './appContextProvider'
import TopBar from '../../app/TopBar'
import DCFooter from '../layout/DCFooter'

const USER = gql`
  query User {
    currentUser {
      id
      displayName
      email
    }
  }
`

type ShellProps = {
  children: React.ReactNode
  version: string
}

export default function Shell({ children, version }: ShellProps) {
  const { context, setContext } = useAppContext()

  const {} = useQuery<{ currentUser: User }>(USER, {
    onCompleted: (res) => setContext({ ...context, user: res.currentUser })
  })

  return (
    <AppShell
      style={(theme) => ({
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        paddingBottom: 0,
        backgroundColor: theme.colors.gray[0]
      })}
      header={{ height: '5rem' }}
      footer={{ height: '3rem' }}
    >
      <AppShell.Header
        style={{
          backgroundClip: 'var(--mantine-color-brand-6)',
          position: 'fixed'
        }}
      >
        <TopBar />
      </AppShell.Header>

      <AppShell.Main>
        <Flex direction="column" h="100%" gap={0} m={0} p={0}>
          <Breadcrumb />
          {/* <WorkspaceTabs /> */}
          <Stack m={0} p={0} gap={0}>
            <>{children}</>
          </Stack>
        </Flex>
      </AppShell.Main>
      <AppShell.Footer>
        <DCFooter version={version} />
      </AppShell.Footer>
    </AppShell>
  )
}
