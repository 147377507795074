'use client'

import { Box, Flex, Stack, Text, Transition } from '@mantine/core'
import React from 'react'
import DataCoalitionsLogoNoText from '../components/svg/DataCoalitionsLogo-notext'
import styles from './styles.module.css'
import { Group as GroupIcon } from '@styled-icons/boxicons-solid'
import { useRouter } from 'next/navigation'

type AppTrayProps = {
  onCloseAction: () => void
  isOpen: boolean
}

export default function AppTray({ onCloseAction, isOpen }: AppTrayProps) {
  const router = useRouter()
  return (
    <Transition mounted={isOpen} transition="fade-down" duration={400} timingFunction="ease">
      {(s) => (
        <Stack className={styles.appTrayContainer} gap={0} style={s}>
          <Text>Flusso Apps</Text>

          <Flex gap="lg" direction="row">
            <Stack
              gap={0}
              align="center"
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onCloseAction()
                router.push('/')
              }}
            >
              <Box h="64px">
                <DataCoalitionsLogoNoText width={100} />
              </Box>
              <Text size="12px">Data Coalitions</Text>
            </Stack>
            <Stack
              gap={0}
              align="center"
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onCloseAction()
                router.push('/apps/participate')
              }}
            >
              <GroupIcon size={64} color={'var(--mantine-color-brand-5)'} style={{ fill: 'url(#brand-gradient)' }} />
              <Text size="12px">Participate</Text>
            </Stack>
          </Flex>
        </Stack>
      )}
    </Transition>
  )
}
